import React, { useRef, useState, useEffect } from 'react';
import { IconSpinner, LayoutComposer, ListingCard } from '../../components/index';
import './CarouselStyles.css';

const ListingPageSwiper = ({ data, title }) => {
  const [importedCarousel, setImportedCarousel] = useState(null);

  useEffect(() => {
    const importedCarousel = async () => {
      const modules = await Promise.all([
          import('swiper/react'),
          import('swiper/modules'),
          import('swiper/css'),
          import('swiper/css/navigation'),
      ]);
    
    const {Swiper, SwiperSlide} = modules[0];
    const {Navigation} = modules[1];

    const swiper = (
      <>
        <Swiper
            modules={[Navigation]}
            spaceBetween={17}
            slidesPerView={5}
            navigation
            breakpoints={{
              320: {
                slidesPerView: 3.5,
                spaceBetween: 5
              },
              550: {
                slidesPerView: 4.5,
                spaceBetween: 15
              },
              769: {
                slidesPerView: 4,
                spaceBetween: 15
              },
              1128: {
                slidesPerView: 5,
                spaceBetween: 17
              }
            }}
        >
          {data.map(listingItem => (
            <SwiperSlide key={listingItem?.id?.uuid}>
              <ListingCard
                listing={listingItem}
                renderSizes={cardRenderSizes(false)} // Assuming this is a function you have defined
                setActiveListing={null} // Pass the appropriate function or value
                squareAspectValue={true}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    );

    if (typeof document !== 'undefined') {
      setImportedCarousel(swiper);
    };
  };

  
  importedCarousel();
  }, [data]);

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  return (
    <>
      <h4 className='title'>{title}</h4>
      {importedCarousel}
    </>
  );
};

export default ListingPageSwiper;
